.header {
    text-align: center;
    height: 60px;
    .header-div{
      width: 33.33%;
    }
    .header-center{
      .logo {
        // height: 40px;
        img {
          max-width: 100%;
        }
      }
      @media (max-width: 419px) {
        width: 40%;
        img{
          max-width: 100%;
        }
      }
    }
    .header-left {
      // width: 50px;
      text-align: left;
      @media (max-width: 419px) {
        width: 28%;
      }
    }
    .header-right {
      text-align: right;
      .rewards-label{
        font-family: "Oswald", sans-serif;
        font-size: 1.1rem;
        color: rgba(255, 199, 0, 0.4%);
        text-transform: uppercase;
        // @media (max-width: 419px) {
        //   font-size: 0.9rem;
        //   letter-spacing: 0.5px;
        // }
      }
      @media (max-width: 419px) {
        width: 28%;
      }
      .logout-btn{
        .logout-icon{
          line-height: 0.9;
        }
        img{
          max-height: 20px;
        }
      }
    }

    .logo{
      img{
        max-width: 100%;
      }
    }
  }