.dashboard-user-rewards{
  background-color: red;
  .dashboard-username{
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  }
}

.dashboardContainer{
  .dashboardUserRewards{
    .dashboardUsername{
      font-size: 2rem;
      font-family: "Oswald", sans-serif;
      font-weight: 400;
      color: #fff;
      text-transform: uppercase;
      max-width: 160px;
      line-height: 1.2;
      @media (max-width: 419px) {
        font-size: 1.5rem;
        max-width: 130px;
      }
    }
    .dashboardReward{
      height: 80px;
      width: 137px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../images/profile-level.svg);
      animation: fadeIn ease 1s;
      -webkit-animation: fadeIn ease 1s;
      -moz-animation: fadeIn ease 1s;
      -o-animation: fadeIn ease 1s;
      -ms-animation: fadeIn ease 1s;
      @media (max-width: 419px) {
        height: 73px;
        width: 123px;
      }
      img{
        height: 110px;
      }
      .dashboardLevelTextwrap{
        .dashboardLevelLabel{
          color: #FFFFFF;
          font-family: "Oswald", sans-serif;
          text-transform: uppercase;
          margin-top: 2px;
          font-size: 0.8rem;
          text-align: center;
          @media (max-width: 419px) {
            font-size: 0.7rem;
          }
        }
        .dashboardLevelValue{
          font-size: 3.2rem;
          font-weight: 400;
          font-family: "Oswald", sans-serif;
          color: #196492;
          text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
          line-height: 0.9;
          text-align: center;
          @media (max-width: 419px) {
            font-size: 2.9rem
          }
        }
      }
    }
  }
  .dashboardAvatarWrap{
    height: calc(100% - 220px);
    position: relative;
    @media (max-width: 419px) {
      height: calc(100% - 220px);
    }
    .dashboardAvatarBG{
      background-image: url(../images/dashboard-bg.jpg);
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 3rem);
      z-index: 1;
      transform: translateY(-50%);
      top: 50%;
    }
    .dashboardbg{
      position: relative;
      img{
        height: calc(100vh - 260px);
        position: relative;
        z-index: 1;
      }
    }
    .logoAnimation{
      position: absolute;
      top: 15%;
      z-index: 10;
      height: 40px;
      width: 40px;
      transform: scale(6);
      transform-origin: center;
      transition: transform 0.8s ease;
      opacity: 0;
      &.animated{
        transform: scale(1);
        opacity: 1;
      }
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.backdrop{
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
}

.rewardWrapper{
  font-family: "Oswald", sans-serif;
  height: 420px;
  transform: translate(-50%, -50%);
  top: -150%;
  background-color: #196492;
  position: fixed;
  left: 50%;
  width: 100%;
  text-transform: uppercase;
  z-index: 100;
  overflow: hidden;
  max-width: 500px;
  .rewardInnerWrapper{
    background-image: url(../images/reward-bg.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .rewardWrapperHeading{
    color: #4FABFF;
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
  }
}

.dahboardPopupBtns{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  @media (min-width: 500px) {
    width: 500px !important;
    transform: translateX(-50%);
    left: 50%;
  }
}

.infoWrapper{
  position: fixed;
  left: 50%;
  top: 150%;
  transform: translate(-50%, -50%);
  background-color: red;
  height: 60%;
  width: 85%;
  z-index: 100;
  transition: all 0.5s ease;
  background-repeat: no-repeat;
    background-image: url(../images/info-bg-geadient.png);
  background-color: #196492;
  background-position: bottom left;
  background-repeat: no-repeat;
  overflow: hidden;
  // &:after{
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(../images/info-bg-person.png);
  //   background-position: bottom right;
  //   background-repeat: no-repeat;
  //   background-size: 40%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
  @media (min-width: 500px) {
    width: 430px !important;
  }
  .infoWrapperIcon{
    img{
      height: 50px;

    }
  }
  .infoWrapperContent{
    height: calc(100% - 177px);
    font-family: "Oswald", sans-serif;
    font-size: 1.1rem;
    color: #fff;
    letter-spacing: 0.7px;
  }
  .infoWrapperFooter{
    img{
      height: 100px;
    }
    span{
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #fff;
      font-family: "Oswald", sans-serif;
      font-weight: 300;
      letter-spacing: 0.7px;
      margin-bottom: 0.8rem;
    }
  }
}

.reward-animation-head{
  width: 0;
  background-color: rgba(0,0,0,0.5);
  font-size: 2rem;
  color: #fff;
  text-align: center;
  overflow: hidden;
  transition: all 0.2s ease;
  white-space: nowrap;
  left: -400px;
  position: relative;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  top: 1.5rem;
  visibility: hidden;
  &.animationPrev{
    width: 100%;
    transition: all 0.3s ease;
    left: 0;
    visibility: visible;
    animation: rewardPrev 1s;
  }
}


.level_badge_wrapper{
  font-size: 6rem;
  font-weight: 600;
  left: -600px;
  transition: all 0.3s ease;
  color: #fff;
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: "Oswald", sans-serif;
  display: none;
  &.animationPrev{
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease;
    display: block;
  }
  &.animationNext{
    text-align: center;
    left: 150%;
    transition: all 0.3s ease;
    display: none;
  }
}



.level{
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 2.8rem
}


.level_badge_wrapper.animationRight{
  text-align: center;
  left: 150%;
  transition: all 0.3s ease;
  display: none;
}

.level-value{
  line-height: 1;
}
.badge-value{
  font-weight: 400;
  font-size: 3rem;
  margin-top: 5rem;
}

.badge-wrapper{
  img{
    height: 210px;
    margin-top: 0.7rem;
  }
}


.overlay{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  animation: fadeVisibleIn ease 2s;
  animation-iteration-count: 2;
  animation-fill-mode: forwards;
  transition: all 0.5s ease;
  display: none;
  &.enabled{
    opacity: 1;
    visibility: visible;
    display: block;
  }
}

.slide-down{
  top: 50%;
  transition: all 0.5s ease;
}

.skip-btn{
  color: #886c10;
  font-family: "Oswald", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}


.slideUp{
  top: 50%;  
  transition: all 0.4s ease;
}


.animated{
  transform: scale(1) !important;
  transition: transform 0.3s ease !important;
  opacity: 1 !important;
}

.animated-bg {
  animation: animatedBackground 20s linear infinite;
  -moz-animation: animatedBackground 20s linear infinite;
  -webkit-animation: animatedBackground 20s linear infinite;
  -ms-animation: animatedBackground 20s linear infinite;
  -o-animation: animatedBackground 20s linear infinite;
}


@keyframes animatedBackground {
  0% { background-position: 0 0; }
  50% { background-position: -300px 0; }
  100% { background-position: 0 0; }
}
@-moz-keyframes animatedBackground {
  0% { background-position: 0 0; }
  100% { background-position: -300px 0; }
}
@-webkit-keyframes animatedBackground {
  0% { background-position: 0 0; }
  100% { background-position: -300px 0; }
}
@-ms-keyframes animatedBackground {
  0% { background-position: 0 0; }
  100% { background-position: -300px 0; }
}
@-o-keyframes animatedBackground {
  0% { background-position: 0 0; }
  100% { background-position: -300px 0; }
}



@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}