.login-box {
    border-radius: 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.7);
}

.hideArrow input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
 }

    /* Firefox */
.hideArrow input[type=number] {
        -moz-appearance: textfield;
}
