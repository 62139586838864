.footer {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 85px;
  @media (max-width: 419px) {
    height: 65px;
  }
  .box {
    transform: rotate(45deg);
    background-image: url(../../Assets/images/footer-right.png);
    border-image-source: url(../images/footer-btns-border.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 300px;
    height: 300px;
    .footer-text {
      transform: rotate(-45deg);
      display: flex;
      position: absolute;
      flex-direction: column;
      color: #fff;
      text-align: center;
    }
  }

  .footer-right {
    flex-grow: 1;
    position: relative;
    .box {
      top: -65px;
      left: 90px;
      @media (max-width: 419px) {
        width: 250px;
        height: 250px;
        top: -60px;
      }
      .footer-text {
        left: 15px;
        bottom: 50px;
        @media (min-width: 320px) {
          left: 11px;
          bottom: 41px;
        }
        @media (min-width: 420px) {
          left: 15px;
          bottom: 45px;
        }
      }
    }
  }
  .footer-center {
    .box {
      top: 40px;
      width: 180px;
      height: 180px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      position: relative;
      @media (max-width: 419px) {
        width: 100px;
        height: 100px;
        top: 22px;
      }      
      .footer-text {
        transform: rotate(-45deg);
        left: 20px;
        top: 10px;
        img{
          margin: 0 auto;
        }
        @media (min-width: 320px) {
          left: 6px;
          top: 2px;
        }
        @media (min-width: 420px) {
          left: 13px;
          top: 13px;
        }
        
      }
    }
  }
  .footer-left {
    flex-grow: 1;    
    position: relative;
    .box {
      top: -65px;
      right: 90px;
      @media (max-width: 419px) {
        width: 250px;
        height: 250px;
        top: -60px;
      }
      .footer-text {
        position: absolute;
        right: 65px;
        top: 5px;
        @media (min-width: 320px) {
          right: 48px;
          top: 0px;
        }
        @media (min-width: 420px) {
          right: 55px;
          top: 5px;
        }
      }
    }
  }
}

.footer-text {
  img {
    max-width: 30px;
    @media (min-width: 320px) {
      max-width: 28px;
    }
    @media (min-width: 420px) {
      max-width: 30px;
    }

  }
  span {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
  }
}

.box {
  background: #ddd;
  cursor: pointer;
  box-shadow: 0 0 5px #ccc inset;  
  box-shadow: 0px 13px 2px 0px rgba(0,0,0,0.25);
  -webkit-box-shadow: 0px 13px 2px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 13px 2px 0px rgba(0,0,0,0.25);
  border: 2px dotted #2196F3;
  border-image-source: url(../images/menu-btns-border.png);
  border-image-slice: 2;
  border-image-width: 2px;
  border-image-repeat: repeat;
}

.menu-wrapper{
  position: fixed;
  z-index: 1;
  bottom: -400px;  
  transition: all 0.6s ease;
  &.opened{
    bottom: 60px;    
    transition: all 0.6s ease;
  }
  // height: 85px;
  @media (min-width: 500px) {
    width: 500px !important;
  }
  .menu-items{
    position: relative;
    // bottom: -400px;
    transition: all 0.6s ease;
    &.opened{
      // bottom: 60px;    
      // transition: all 0.6s ease;
      // @media (max-width: 419px) {
      //   bottom: 60px;
      // }
      .d-three{
        animation: translateDThree 0.8s;
        
      }
    }
  }  
  .diamond{
    transform: rotate(-45deg);
    background-image: url(../images/menu-btns.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 150px;
    height: 150px;
    box-shadow: -7px 7px 2px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: -7px 17px 2px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: -7px 7px 2px 0px rgba(0,0,0,0.25);
    border: 2px solid #2196F3;
    border-image-source: url(../images/menu-btns-border.png);
    border-image-slice: 2;
    border-image-width: 2px;
    border-image-repeat: repeat;
    flex: 0 0 auto;
    .diamond-text{
      position: relative;
      // transform: rotate(-45deg);
      width: 100%;
      height: 100%;
      .diamond-icon{
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        img{          
          transform: rotate(45deg) translateY(-8px);
        }
      }
      .diamond-label{
        text-align: center;
        color: #fff;
        transition: opacity 0s linear .3s;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        font-size: 0.8rem;
        letter-spacing: 0.5px;
      }
    }
    &.d-one{
      width: 100px;
      height: 100px;
      left: -5px;
      top: 5px;
      @media (max-width: 419px) {
        width: 90px;
        height: 90px;
        left: 5px;
        top: 5px;
      }
      .diamond-icon{
        img{          
          margin: 0 12px 0 0;
          width: 50px;
          @media (max-width: 419px) {
            width: 50px;
          }
        }
      }
    }
    &.d-two{
      width: 78px;
      height: 78px;
      bottom: -80px;
      left: -10px;
      @media (max-width: 419px) {
        width: 68px;
        height: 68px;
        bottom: -75px;
        left: 0px;
      }
      .diamond-icon{
        img{
          max-width: 40px;
          margin-right: 8px;
          @media (max-width: 419px) {
            max-width: 40px;
          }
        }
      }
    }
    &.d-three{
      width: 100px;
      height: 100px;
      @media (max-width: 419px) {
        width: 90px;
        height: 90px;
        left: -45px;
        top: -35px;;
      }
      left: -50px;
      top: -35px;
      .diamond-icon{
        img{
          max-width: 50px;
          @media (max-width: 419px) {
            max-width: 45px;
          }
        }
      }
    }
    &.d-four{
      width: 140px;
      height: 140px;  
      @media (max-width: 419px) {
        width: 120px;
        height: 120px;
        left: -40px;
        top: 10px;
      }    
      left: -30px;
      top: 0px;
      .diamond-icon{
        img{
          width: 110px;
          @media (max-width: 419px) {
            width: 90px;
          }
        }
      }
    }
    &.small{
      background-color: rgba(79, 171, 255, 0.5);
      background-image: none;
      box-shadow: none;
      border: 0;
    }
    &.small-1{
      width: 25px;
      height: 25px;
      bottom: 0;
      left: -170px;
    }
    &.small-2{
      width: 50px;
      height: 50px;
      bottom: 40px;
      left: -110px;
    }
    &.small-3{
      width: 25px;
      height: 25px;
      bottom: 0;
      right: -120px;
    }
  }
  .static-diamonds{
    position: relative;
    justify-content: center;
    display: none;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 170px;
  }

  &.dashBoardDiamonds{
    height: auto;
    z-index: 11;
    // .menu-items{
    //   &.opened{
    //     bottom: 60px;
    //   }
    // }
  }
}


@keyframes translateDThree {
  0% {
    top: 30px;
  }
  100% {
    top: -35px;
  }
}
