@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 0.95rem;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.wrapper {
    background-color: #001223;
    background-repeat: no-repeat;
    background-size: cover;
}

.element {
    border-radius: 20%;
    color: rgb(255, 255, 255);
    animation: highlight  1s ease-in-out infinite;
    
  }
  

  @keyframes highlight {
    from {
        background-color: rgba(255, 255, 255, 0.2);
 -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transform:scale(1.05);


    }
  }

.container {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    background: linear-gradient(
        180deg,
        #001223 0%,
        rgba(29, 101, 150, 1) 50.52%,
        #001425 92.4%
    );
    @media (min-width: 500px) {
        width: 500px;
    }
    @media (max-width: 767px) {
        // padding: 0 1rem;
        // max-width: 760px;
    }
}

.page-content {
    height: calc(100% - 60px);
    overflow-y: auto;
    position: relative;
    // @media (orientation: landscape) {
    //   min-height: 400px;
    // }
}

.login-box {
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    // background-color: rgba(0, 0, 0, 0.7);
    max-width: 380px;
    color: #fff;
    margin: 0 auto;
    .p-float-label {
        .p-inputtext {
            background: #092c47;
            color: #fff;
            border: 0;
            box-shadow: none;
        }
        label {
            color: #7a9fb9;
        }
    }
    .login-btn {
        height: 45px;
    }
    .p-invalid {
        color: #ff8888;
    }
    .anchor-link {
        text-decoration: underline;
    }
    .or-text {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        &::before {
            height: 1px;
            background-color: red;
            flex-grow: 1;
        }
        &::before,
        &::after {
            content: "";
            background-color: rgba(255, 255, 255, 0.5);
            height: 1px;
        }
        &::before {
            flex-grow: 1;
            margin-right: 0.8rem;
            margin-left: 2rem;
        }
        &::after {
            flex-grow: 1;
            margin-left: 0.8rem;
            margin-right: 2rem;
        }
    }

    .sign-up-btn {
        height: 40px;
        color: #fff;
        border-radius: 4px;
    }
}

.without-footer {
    .page-content {
        height: calc(100% - 60px);
    }
}

.splash-screen {
    // background-color: #fff;
    height: calc(100% - 50px);
    padding: 3rem;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.p-float-label .p-inputtext {
    padding-top: 1.5rem;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    top: 15px !important;
}

.p-float-select-label > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}
.p-float-select-label > .form-control,
.form-floating > .form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
}
.form-select {
    display: block;
    width: 100%;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #092c47;
    color: #fff;
    background-image: url;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
}

.p-float-select-label > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.p-float-select-label > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.5rem;
    pointer-events: none;
    border: 1px solid transparent;
    font-size: 12px;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.p-invalid {
    color: #ff3300;
    font-weight: 600;
}

.btn {
    background-repeat: repeat;
    background-size: contain;
    background-image: url(Assets/images/button-bg.png);
    background-color: transparent;
    height: 50px;
    border: 0;
    font-weight: 700;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #fff;
    &:focus {
        box-shadow: none;
    }
}

.popup-body {
    // background-color: rgba(0, 0, 0, 0.7);
    color: #c8e0f5;
    text-align: center;
}

.space-around {
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: space-around;
    transition: all 0.5s ease;
}

.fade-out {
    display: none !important;
    transition: all 0.5s ease;
    animation: fadeOut 2s;
}

.fade-in {
    display: flex !important;
    transition: all 0.5s ease;
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

.welcome-text {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    p {
        margin-bottom: 1.2rem;
        line-height: 1.4rem;
        font-size: 1rem;
    }
    img {
        max-width: 25px;
        margin: 0 0.5rem;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 8px;
    }
}

.welcome-video {
    .popup-body {
        min-height: 290px;
        overflow-y: hidden;
    }
}

.welcome-container-title h3{
    font-family: "Oswald", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: left;
    margin: 0;
    margin: 0px 0 6px 0;
}
.welcome-container-title h4{
    font-family: "Oswald", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    color: #5B9BD5;
    text-align: left;
    margin: 0 0 15px 0;
}
.welcome-container-img{
    width: 100%;
    object-fit: contain;
}

// .avataar-popup{
//   position: absolute;
//   z-index: 100;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: red;
// }

.preload * {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hide {
    display: none !important;
}

.fadeVisibleOutOnly {
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease;
}

.fadeVisibleOut {
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease;
    display: none;
}

.fadeVisibleInOnly {
    opacity: 1;
    visibility: visible;
    transition: all 1s ease;
}

.fadeVisibleIn {
    opacity: 1;
    visibility: visible;
    transition: all 1s ease;
    display: flex;
}

@keyframes fadeVisibleOut {
    0% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
        visibility: hidden;
    }
}

@keyframes fadeVisibleIn {
    0% {
        opacity: 1;
        visibility: hidden;
    }
    100% {
        opacity: 0;
        visibility: visible;
    }
}

.inner-page-wrapper {
    .inner-page-header {
        // background: linear-gradient(98.09deg, rgba(255, 255, 255, 0) 32.93%, #FFFFFF 66.95%, rgba(255, 255, 255, 0.51) 79.93%, #FFFFFF 90.1%);
        background-color: #858585;
        text-align: center;
        font-family: "Oswald", sans-serif;
        font-weight: 700;
        background-image: url(Assets/images/inner-header.png);
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        padding-top: 0.7rem;
        padding-bottom: 0.8rem;
        h1 {
            line-height: 1;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
    .inner-page-content {
        height: calc(100% - 45px);
        overflow-y: auto;
        &:after {
            content: "";
            height: 80px;
            display: block;
            width: 100%;
            flex-shrink: 0;
        }
    }
    .static-diamonds {
        display: none;
    }
    &.conversation-page-wrapper {
        .inner-page-content {
            &:after {
                display: none;
            }
        }
    }
}

.experience-list {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            padding: 1.3rem 1rem;
            border-radius: 4px;
            background-color: #5b9bd5;
            margin-bottom: 1rem;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 1rem;
            letter-spacing: 0.3px;
            display: flex;
            justify-content: space-between;
            .experience-name {
                color: #fff;
            }
            .experience-crs-date {
                font-size: 0.9rem;
                color: #fff;
                font-weight: 500;
            }
            &.completed {
                .experience-name {
                    color: #196492;
                }
            }
            &.active {
                background-color: #a65cb9;
            }
        }
    }
}

.dashBoardDiamonds {
    .static-diamonds {
        display: flex;
    }
}

.chat-loading {
    position: relative;
}

.loading {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #25527b /*#5197d6*/;
    border-radius: 50%;
    /*box-shadow: 0 0 2px black;*/
    animation: loading 4s infinite;
}

.loading:nth-child(1) {
    animation-delay: 0.6s;
    width: 6px;
    height: 6px;
    left: -15px;
}

.loading:nth-child(2) {
    animation-delay: 0.8s;
    width: 4px;
    height: 4px;
}

.loading:nth-child(3) {
    animation-delay: 1s;
    width: 4px;
    height: 4px;
    right: -15px;
}

.loading {
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
}

@keyframes loading {
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(0px);
    }
    30% {
        transform: translateY(-8px);
    }
    40% {
        transform: translateY(5px);
    }
    50% {
        transform: translateY(-2px);
    }
    60% {
        transform: translateY(2px);
    }
    80% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}

.slideUp {
    bottom: 0;
    transition: all 0.4s ease;
}
.conersation-agents-wrap {
    flex-wrap: wrap;
    flex-grow: 1;
    .conersation-agent {
        width: 110px;
        height: 110px;
        margin: 0.8rem;
        position: relative;
        &.selected {
            .conersation-agent-img {
                border-color: #1acf4d;
                &::before {
                    content: "";
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-image: url(./Assets/images/tick.png);
                    background-size: 70%;
                    background-position: center;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 10;
                    background-repeat: no-repeat;
                }
            }
        }
        .conersation-agent-img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 50%;
            flex-grow: 0;
            flex-shrink: 0;
            border: 5px solid #c4c4c4;
            img {
                max-height: 100%;
            }
        }
    }
}

.placeholder-img{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    img{
        height: auto !important;
    }
}

.conersation-agent-img {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    border: 5px solid #c4c4c4;
    position: relative;
    img {
        max-height: 100%;
        position: relative;
        z-index: 10;
    }
}

.discussion {
    flex-grow: 1;
    overflow-y: auto;
}

.conersation-start-text {
    color: #ecf6ff;
    font-size: 1.1rem;
    // background-color: rgba(0, 0, 0, 0.25);
}

.password-text-erap {
    .pi {
        position: absolute;
        font-size: 1.2rem;
        top: 19px;
        right: 15px;
        // color: #052139;
    }
}

.questionaire {
    .questionaire-top-quote {
        background-color: rgba(0, 0, 0, 0.25);
        h2 {
            font-weight: 300;
            color: #fff;
            font-size: 1.3rem;
            width: 90%;
            text-align: center;
            padding-bottom: 2rem;
            margin-top: 0;
            letter-spacing: 0.5px;
            margin-bottom: 0.5rem;
        }
        .questionaire-top-quote-inner {
            position: relative;
            top: -25px;
        }
        .questionaire-quote-person {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            overflow: hidden;
            border: 4px solid #fff;

            img {
                max-width: 100%;
            }
        }
        h3 {
            font-weight: 400;
            color: #fff;
            font-size: 2rem;
        }
    }
    .questionaire-bottom-topic {
        .questionaire-bottom-head {
            font-size: 1.4rem;
            color: #fff;
            font-weight: 300;
        }
    }
}

.conv-avatar-section {
    .conv-top-section {
        background-color: rgba(0, 0, 0, 0.3);
        .conv-top-section-inner {
            height: 60px;
            .conv-top-section-header {
                .conv-top-section-person {
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 5px solid #fff;
                    flex: 0 0 auto;
                    img {
                        max-width: 100%;
                    }
                }
                h3 {
                    font-weight: 400;
                    color: #fff;
                    font-size: 1.5rem;
                }
            }
            .conv-top-section-quote {
                font-size: 0.95rem;
                color: #fff;
                line-height: 1.5;
            }
        }
        &.quadrant-section {
            .conv-top-section-inner {
                height: auto;
            }
        }
    }
}
.conv-question-section {
    color: #fff;
}
.conv-question-types {
    background-color: rgba(0, 0, 0, 0.6);
    // flex-grow: 1;
    .activity-img-outer {
        background-color: transparent;
        height: 220px;
        margin-top: 0;
        .activity-img {
            height: 100%;
            img {
                height: 100%;
            }
        }
        .activity-row-start {
            margin-top: 0;
        }
        .activity-row-end {
            margin-bottom: 0;
        }

        .activity-items-wrapper {
            width: auto;
            flex-wrap: wrap;
            .activity-diamond-wrapper {
                flex: 0 0 41%;
                margin: 10px 0;
                &:nth-child(1),
                &:nth-child(4) {
                    .activity-type-icon {
                        text-align: right;
                    }
                }
                &:nth-child(2),
                &:nth-child(3) {
                    .activity-type-icon {
                        text-align: left;
                    }
                }
                &:nth-child(3),
                &:nth-child(4) {
                    flex-direction: column-reverse;
                    display: flex;
                }

                &:nth-child(1) {
                    order: 1;
                }
                &:nth-child(2) {
                    order: 2;
                }
                &:nth-child(3) {
                    order: 4;
                }
                &:nth-child(4) {
                    order: 3;
                }
                .story-type {
                    font-size: 1.125rem;
                    text-transform: capitalize;
                    color: #fff;
                    font-weight: 300;
                }
                .activity-type-icon {
                    position: static;
                    img {
                        max-width: 37px;
                    }
                }
            }
        }
    }
}


.conv-thoughts-textarea {
    background-color: rgba(0, 0, 0, 0.6);
    flex-grow: 1;
    textarea {
        border: 0;
        padding: 0.7rem;
        font-family: "Roboto", sans-serif;
        font-size: 1.1rem;
    }
}

.main-topics {
    flex-grow: 1;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            color: #fff;
            background: #a65cb9;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            margin-bottom: 0.8rem;
            padding: 0.5rem;
            font-size: 0.85rem;
            font-weight: 300;
            letter-spacing: 0.6px;
            min-height: 40px;
            display: flex;
            align-items: center;
        }
    }
}

.selected-avatar {
    height: 200px;
    flex-shrink: 0;
    position: relative;
    @media (max-width: 419px) {
        // display: none;
    }
    img {
        max-height: 100%;
        max-width: 100%;
    }
    .selected-avatar-logo {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 15px;
        height: 15px;
    }
}

.interviewee-col {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
}

.interviewee-person {
    background-color: #a8a9a4;
    height: 350px;
    position: relative;
    padding: 1.5rem 0;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
        width: 100%;
    }
    .interviewee-person-head {
        font-weight: 600;
        font-size: 1.8rem;
        color: #fff;
    }
    &.interviewee-person-active {
        &:after {
            display: none;
        }
    }
}

.qn-icon {
    img {
        height: 60px;
    }
}

.interview-btn {
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    width: 95%;
    margin: 0.5rem auto;
}
.interview-sub-title {
    font-size: 1.2rem;
    color: #fff;
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.interview-topics ul li {
    font-size: 1rem;
}

.interviewee-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #000000;
    margin-top: 1rem;
}

.backtodiv {
    span {
        opacity: 0.7;
        display: inline-block;
    }
    .anchor-link {
        opacity: 1;
        font-size: 1rem;
    }
}

.popup-body {
    max-height: calc(100% - 80px);
    overflow-y: auto;
}

.complete-page {
    &::after {
        display: none !important;
    }
    .complete-page-text {
        height: calc(100% - 180px);
        color: #fff;
        p {
            text-align: center;
            font-size: 1.5rem;
            margin: 0;
            font-weight: 300;
        }
        .earned-points {
            text-align: center;
            display: block;
            font-weight: 800;
            font-size: 4rem;
        }
    }
    .btn {
        width: 80%;
        margin: 0 auto;
        @media (max-width: 419px) {
            width: 95%;
        }
    }
}

.conersation-agents-content {
    // height: calc(100% - 80px);
}

.conersation-agents-page-wrapper .inner-page-content {
    display: flex;
    flex-direction: column;
}
.conersation-agents-content {
    flex-grow: 1;
}

.no-topic {
    color: #c8e0f5;
    text-align: center;
    font-size: 1rem;
}

.option-end-btn-wrap {
    padding: 1rem;
}

.skip-to-last {
    color: #ddecf9;
    text-align: center;
    margin-bottom: 10px;
    text-decoration: underline;
}

.image-video-viewer {
    text-align: center;
    img {
        max-width: 100%;
    }
    div {
        width: 100% !important;
        height: auto !important;
    }
}

.question-text-format {
    white-space: pre-wrap;
}

.disabled {
    pointer-events: none; //This makes it not clickable
    opacity: 0.6; //This grays it out to look disabled
}


.coach-selection-wrap{
    .conersation-agent{
        width: 180px;
        height: 180px;
        img{
            height: 100%;
        }
    }
    .slick-prev, .slick-next{
        height: auto;
        width: auto;
        top: 100px;
    }
    .slick-prev{
        left: 20px;
    }
    .slick-next{
        right: 20px;
    }
    .slick-next:before {
        content: "";
        background-image: url(../src/Assets/images/right-arrow.png);
        height: 38px;
        width: 24px;
        background-size: 24px;
        display: inline-block;
    }
    .slick-prev:before {
        content: "";
        background-image: url(../src/Assets/images/right-arrow.png);
        height: 38px;
        width: 24px;
        background-size: 24px;
        display: inline-block;
        position: relative;
        transform: rotate(-180deg);
    }

    
}

.coach-selection-wrap .slick-slider, 
.coach-selection-wrap .slick-list, 
.coach-selection-wrap .slick-track, 
.coach-selection-wrap .slick-slide{
    height: 100%;
}


.medium-btn{
    height: 35px;
}

.mt-top-auto{
    margin-top: auto;
}

