$active-task: #71ae48;
$passive-task: #4fabff;
$past-task: #A65CB9;

.tasks-page-wrapper {
  .tasks {
    .tasks-header {
      border-bottom: 3px solid #4fabff;
      font-size: 1.3rem;
      font-weight: 400;
      color: #fff;
      font-family: "Oswald", sans-serif;
      letter-spacing: 0.5px;
    }
    .tasks-content {
      // background-color: rgba(0, 0, 0, 0.25);
    }
    .task-list {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // margin-bottom: 1.5rem;
        .task-full-width {
          margin-right: 0 !important;
          .task-item-left-top {
            border-top-right-radius: 5px;
          }
          .task-item-left-bottom {
            border-bottom-right-radius: 5px;
            overflow: hidden;
          }
        }
        .task-item {
          overflow: hidden;
          .task-userimage{
            border: 7px solid #fff;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background-color: #fff;
            overflow: hidden;
            z-index: 10;
            img{
              max-width: 100%;
            }
          }
          .task-name{
            flex-grow: 1;
          }
          .date{
            color: rgba(0,0,0,0.60);
            font-size: 0.85rem;
          }
          .task-btn{
            min-width: 80px;
            font-size: 0.85rem;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            span{
              width: 40px;
            }
          }
          .active-task-wrapper{
            flex-grow: 1;
            display: flex;
            height: 40px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            .task-name{
              flex-grow: 1;
              background-color: #fff;
              margin-left: -1rem;
              padding-left: 1rem;
            }
          }
          .play-again-left-wrap{            
            flex-grow: 1;
            background-color: #fff;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            .task-type {
              .task-type-img {
                height: 23px;
                text-align: center;
                img {
                  max-height: 100%;
                }
              }
              span {
                text-transform: uppercase;
                font-size: 0.5rem;
                font-weight: 600;
                color: $past-task;
              }
            }
            .task-val {
              font-size: 1.6rem;
            }
          }          
          .task-btn{
            background-color: #71AE48;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

        }
        &.completed {
          margin-bottom: 0.8rem;
          .task-item {
            .task-btn{
              background-color: $past-task;
            }
            .task-val{
              color: $past-task;
            }
            .task-btn{
              background-color: $past-task;
            }
          }
          .task-item-left-bottom {
            .task-item-progress {
              width: 100%;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        
      }

      
    }
  }


  .tasks {
    &.active-tasks {
      .task-item-progress {
        color: $active-task;
      }
      .task-item-right {
        span {
          color: $active-task;
        }
        .task-val {
          color: $active-task;
        }
      }
      .task-item-progress {
        background-color: $active-task;
      }
    }

  }
  .task-btn-wrap{
    height: 48px;
    .task-btn {
      border-radius: 4px;
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      width: 180px;
      height: 30px;
    }
  }
  .task-list-wrap{
    // height: calc(100% - 48px);
    // overflow-y: auto;
  }
  

  .tasks-page-wrapper {
    z-index: 10;
  }
}




