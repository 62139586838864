

    
  .diamond{
    transform: rotate(-45deg);
    background-image: url(../images/menu-btns.png);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    // width: 150px;
    // height: 150px;
    box-shadow: -7px 7px 2px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: -7px 17px 2px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: -7px 7px 2px 0px rgba(0,0,0,0.25);
    border: 3px dotted #2196F3;
    border-image-source: url(../images/menu-btns-border.png);
    border-image-slice: 3;
    border-image-width: 3px;
    border-image-repeat: repeat;
    flex: 0 0 auto;
    margin-top: 3rem;
    .diamond-text{
      position: relative;
      // transform: rotate(-45deg);
      width: 100%;
      height: 100%;
      .diamond-icon{
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        img{          
          transform: rotate(45deg) translateY(-8px);
        }
      }
      .diamond-label{
        text-align: center;
        color: #fff;
        transition: opacity 0s linear .3s;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        font-size: 0.8rem;
        letter-spacing: 0.5px;
      }
    }
    &.d-four{ 
      @media (min-width: 320px) {
        width: 100px;
        height: 100px;
      }
      // @media (min-width: 400px) {
      //   width: 130px;
      //   height: 130px;
      // }
      .diamond-icon{
        img{
          @media (min-width: 320px) {
            width: 60px;
          }
          // @media (min-width: 400px) {
          //   width: 70px;
          // }
        }
      }
    }

    &.logout{
      .diamond-icon{
        img{
          width: 40px;
        }
      }
    }
    &.avatar-icon{
      .diamond-icon{
        img{
          width: auto;
          height: 90%;
          
        }
      }
    }
    
  }

.odd{
    margin-right: 1.5rem;
}

.even{
    margin-left: 1.5rem;
}

.confirmation-message{
    position: fixed;
    left: 50%;
    top: 150%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 1000;
    transition: all 0.5s ease;
    background-repeat: no-repeat;
    background-image: url(../images/info-bg-geadient.png);
    background-color: #196492;
    background-position: bottom left;
    background-repeat: no-repeat;
    overflow: hidden;
    color: #fff;
    @media (min-width: 500px) {
      width: 450px;
    }
    h3{
      margin: 0;
    }
    &.active{
      top: 50%;
    }
    .confirmation-message-footer{
      .btn{
        height: 30px;
      }
    }
}

.confirm-backdrop{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.25);
  display: none;
  &.active{
    display: block;
  }
}