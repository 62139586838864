.activity-img-outer{
    position: relative;
    height: 250px;
    background-color: rgba(0,0,0,0.3);
    margin-top: 20px;
    .activity-img{
        position: absolute;
        width: 100%;
        height: 130%;
        text-align: center;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
    .activity-items-wrapper{
        position: relative;
        z-index: 10;
        width: 320px;
        margin: 0 auto;
        .activity-diamond-wrapper{
            position: relative;
            .activity-diamond{
                width: 110px;
                height: 110px;
                background-image: url(../images/activity-diamond.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                align-items: center;
                justify-content: center;
                .type-label{
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 500;
                    font-size: 0.8rem;
                }
                .type-level{
                    font-weight: 700;
                    font-size: 3rem;
                    color: #fff;
                    line-height: 1;
                }
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
    
            .activity-level{
                font-size: 6rem;
                font-weight: 400;
                font-family: "Oswald", sans-serif;
                color: #196492;
                text-shadow: -4px -4px 0 #fff, 4px -4px 0 #fff, -4px 4px 0 #fff, 4px 4px 0 #fff;
                line-height: 1;
                text-align: center;
            }
            .activity-type-icon{
                position: absolute;
                img{
                    max-width: 60px;
                }
            }

    
            


            &.tl{
                .activity-type-icon{
                    left: 70px;
                    top: 70px;
                }
            }
            &.tr{
                .activity-type-icon{
                    left: -30px;
                    top: 70px
                }
            }
            &.bl{
                .activity-type-icon{
                    top: -20px;
                    left: 70px;
                }
            }
            &.br{
                .activity-type-icon{
                    top: -15px;
                    right: 70px;
                }
            }
        }
    }

    .activity-row-start{
        margin-top: -40px;
    }
    .activity-row-end{
        margin-bottom: -40px;
    }

    .activity-level{
        font-size: 6rem;
        font-weight: 400;
        font-family: "Oswald", sans-serif;
        color: #196492;
        text-shadow: -4px -4px 0 #fff, 4px -4px 0 #fff, -4px 4px 0 #fff, 4px 4px 0 #fff;
        line-height: 1;
        text-align: center;
    }
    
}

.activity-user-name{
    transform: rotate(-45deg);
    background-color: rgba(91,155,213, 0.5);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 150px;
    height: 150px;
    // box-shadow: -7px 7px 2px 0px rgba(0, 0, 0, 0.25);
    border-image-repeat: repeat;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;
    top: 1rem;
    z-index: 10;
    left: 0;
    .activity-user-name-text{

        transform: rotate(45deg);
        font-family: "Oswald", sans-serif;
        color: #fff;
        font-weight: 400;
        font-size: 1.6rem;
        text-transform: uppercase;
        position: relative;
        left: 35px;
        width: 1100px;
        z-index: 10;
        top: 10px;
    }
}

.activity-list-header {
    border-bottom: 3px solid #4fabff;
    font-size: 1.3rem;
    font-weight: 400;
    color: #fff;
    font-family: "Oswald", sans-serif;
    letter-spacing: 0.5px;
  }
  .activity-list-content {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .activity-list{
      list-style: none;
      padding: 0;
      margin: 0;
      .activity-list-wrapper{
            font-family: "Oswald", sans-serif;
            font-size: 1rem;

          .activity-list-left{
              width: 60%;
              color: #4FABFF;
              letter-spacing: 0.5px;
          }
          .activity-list-right{
            width: 40%;
            color: #fff;
            letter-spacing: 0.3px;
        }
      }
  }

  .activity-contaianer {
      padding-bottom: 80px;
  }
  .activity-contaianer-outermost-block{
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
  }
  .activity-contaianer-outer-block{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .activity-diamond, .activity-level{
    animation: fadeIn ease 1.5s;
    -webkit-animation: fadeIn ease 1.5s;
    -moz-animation: fadeIn ease 1.5s;
    -o-animation: fadeIn ease 1.5s;
    -ms-animation: fadeIn ease 1.5s;
  }

  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }