



.hide {
  display: none !important;
}

// .avatar-expanded {
//   display: block !important;
//   .avatar-image {
//     height: calc(100% - 150px);
//     img {
//       margin-top: 21px;
//     }
//   }
//   .avatar-info-wrapper {
//     display: none;
//   }
// }

.fadeVisibleOutOnly {
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease;
}

.even + .odd {
  border-bottom-right-radius: 5px;
}

.even + .odd {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.even:last-of-type {
  border-bottom-right-radius: 30px;
}

.OptionClass {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    li {
      display: block;
      background-color: #A65CB9;
      padding: 0.5rem;
      margin: 0.5rem;
      border-radius: 5px;
      font-size: 0.8rem;
      color: #fff;
    }
  }
}

.speech-wrapper {
  padding: 15px 15px;
  .bubble {
    width: 240px;
    height: auto;
    display: block;
    background: #e5e6ea;
    border-radius: 4px;
    box-shadow: 2px 8px 5px #000;
    position: relative;
    margin: 0 0 25px;
    &.alt {
      margin: 0 0 30px 60px;
      background-color: #40a1fc;
      margin-left: auto;
      .message {
        color: #fff;
      }
    }
    .txt {
      padding: 8px 55px 8px 14px;
      .name {
        font-weight: 600;
        font-size: 12px;
        margin: 0 0 4px;
        color: #3498db;
        span {
          font-weight: normal;
          color: #b3b3b3;
        }
        &.alt {
          color: #2ecc71;
        }
      }
      .message {
        font-size: 12px;
        margin: 0;
      }
      .timestamp {
        font-size: 11px;
        position: absolute;
        bottom: 8px;
        right: 10px;
        text-transform: uppercase;
        color: #999;
      }
    }
    .bubble-arrow {
      position: absolute;
      width: 0;
      bottom: 42px;
      left: -16px;
      height: 0;
      &.alt {
        right: -2px;
        bottom: 40px;
        left: auto;
      }
    }
    .bubble-arrow:after {
      content: "";
      position: absolute;
      border: 0 solid transparent;
      border-top: 9px solid #f5f5f5;
      border-radius: 0 20px 0;
      width: 15px;
      height: 30px;
      transform: rotate(145deg);
    }
    .bubble-arrow.alt:after {
      transform: rotate(45deg) scaleY(-1);
      border-top-color: #40a1fc;
    }
  }

  // .avatar-expanded {
  //   display: block !important;
  //   .avatar-image {
  //     height: calc(100% - 150px);
  //     img {
  //       margin-top: 40px;
  //     }
  //   }
  //   .avatar-info-wrapper {
  //     display: none;
  //   }
  // }

  .fadeVisibleOut {
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease;
    display: none;
  }

  .fadeVisibleIn {
    opacity: 1;
    visibility: visible;
    transition: all 1s ease;
    display: flex;
  }
}








.discussion {
	display: flex;
  padding: 1rem;
  .bubble {
    border-radius: 1em;
    padding: 0.75em;
    margin: 0.0625em;
    max-width: 80%;
    margin-bottom: 0.625rem;
    .name-wrapper{
      margin: 0;
      .name{
        font-weight: 700;
      }
      .timestamp{
        font-size: 0.8rem;
      }
    }
    .message{
      margin: 5px 0 0 0;
    }
    &.sender {
      align-self: flex-end;
      background-color: #efefef;
      &.first { border-bottom-right-radius: 0.1em; }
      &.last { border-top-right-radius: 0.1em; }
      &.middle {
        border-bottom-right-radius: 0.1em;
         border-top-right-radius: 0.1em;
      }
    }
    &.recipient {
      align-self: flex-start;
      background-color: #557ea5;
      color: #fff;
      &.first { border-bottom-left-radius: 0.1em; }
      &.last { border-top-left-radius: 0.1em; }
      &.middle {
        border-bottom-left-radius: 0.1em;
        border-top-left-radius: 0.1em;
      }
    }
  }
}

.chat-loading{
  .bubble{
    position: relative;
    width: 50px;
  }
}

.audio-viewer{
  width: 100%;
  div{
    height: 35px !important;
    width: 100% !important;
  }
  audio{
    width: 240px !important;
  }
}