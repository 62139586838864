.badge-list{
    .badge-wrap{
        margin: 0 -0.3rem;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        // max-width: 320px;
        list-style: none;
        // @media (min-width: 320px) {            
        //     max-width: 288px;
        // }
        // @media (min-width: 380px) {            
        //     max-width: 350px;
        // }
        .badge-item{
            margin: 0.3rem;
            width: calc(33.33% - 0.6rem);
            padding-top: calc(33.33% - 0.6rem); /* 1:1 Aspect Ratio */
            position: relative;
            // @media (min-width: 320px) {
            //     width: 96px;
            //     height: 96px;
            // }
            // @media (min-width: 380px) {
            //     width: 116px;
            //     height: 116px;
            // }
            .badge{
                width: 100%;
                height: 100%;
                border-radius: 100%;
                // background-color: rgba(0,0,0,0.30);
                background-color: #fff;
                overflow: hidden;
                border: 3px solid #fff;
                position:  absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                box-shadow: 0px 4px 4px rgba(0,0,0,0.4);
                img{
                    width: 100%;
                }
            }
        }
    }
}