.avatar-info {
    font-size: 1.6rem;
    color: #fff;
  }
  
  .avataar-page-wrapper {
    justify-content: space-around;
    .avatar-image {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      overflow-y: auto;
      flex-grow: 1;
      &:after{
        content: "";
        height: 50px;
        display: block;
        width: 100%;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50%;
        padding: 1rem;
        transition: all 0.5s ease;
        &:nth-child(odd){
          padding-left: 3rem;
          @media (min-width: 320px) {
            padding-left: 1.5rem;
          }
          @media (min-width: 400px) {
            padding-left: 3rem;
          }

        }
        &:nth-child(even){
          padding-right: 3rem;
          @media (min-width: 320px) {
            padding-right: 1.5rem;
          }
          @media (min-width: 400px) {
            padding-right: 3rem;
          }

        }
        img {
          height: 100%;
          transition: all 0.5s ease;
        }
        &:nth-child(1) {
          left: 0;
        }
        &:nth-child(2) {
          right: 0;
        }
        &:nth-child(3) {
          left: 0;
          bottom: 0;
        }
        &:nth-child(4) {
          right: 0;
          bottom: 0;
        }
        &.zoom {
          width: 100%;
          height: 100%;
          transition: all 0.8s ease;
          animation: zoomAnimation ease 3s;
          padding: 0;
          img {
            height: calc(100vh - 230px);
            position: relative;
            top: -37px;
            @media (min-width: 400px) {
              top: -30px;
            }
          }
        }
      }
    }
    .avatar-info-wrapper {
      // height: 75px;
      flex-shrink: 0;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      height: 50px;
    }
  }
  
  // .avatar-expanded {
  //   display: block !important;
  //   .avatar-image {
  //     // height: calc(100% - 150px);
  //     img {
  //       //  margin-top: 21px;
  //     }
  //   }
  //   .avatar-info-wrapper {
  //     display: none;
  //   }
  // }
  

  @keyframes zoomAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }