.library-item-wrapper {
    .library-header {
      border-bottom: 3px solid #4fabff;
      font-size: 1.3rem;
      font-weight: 400;
      color: #fff;
      font-family: "Oswald", sans-serif;
      letter-spacing: 0.5px;
    }
    .library-content {
      background-color: rgba(0, 0, 0, 0.25);
      &.full-list{
        background-color: transparent;
        ul{
          flex-wrap: wrap;
          li {
            margin-bottom: 0.6rem;
            width: calc(33.33% - 0.6rem);
            padding-top: calc(33.33% - 0.6rem);
          }
        }
      }
    }
    .library-list {
      list-style: none;
      padding: 0;
      margin: 0 -0.3rem;
      display: flex;
      li {
        width: calc(25% - 0.6rem);
        padding-top: calc(25% - 0.6rem);
        background-color: rgba(0, 0, 0, 0.25);
        position: relative;
        margin: 0 0.3rem;
        .library-item{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
            color: #fff;
            font-weight: 400;
            text-transform: uppercase;
            font-family: "Oswald", sans-serif;
            letter-spacing: 0.5px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .library-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 120%;
    transition: all 0.5s ease;
    @media (min-width: 500px) {
      width: 500px;
    }
    .modal-item-wrapper{
      width: 100%;
      height: calc(100% - 120px);
      background-color: #196492;
      display: flex;
      flex-direction: column;
      .modal-item-image{
        height: 60%;
        width: 100%;
        background-size: cover;
        background-position: center;
        &.library-item-image{
          box-shadow: 0px 4px 5px rgba(0,0,0,0.3);

        }
      }
      .badge-item-image{
        // margin: 0.3rem;
        width: calc(70% - 0.6rem);
        padding-top: calc(70% - 0.6rem);
        position: relative;
        margin: 0 auto;
        height: auto;
        @media (min-width: 400px) {
          width: calc(70% - 0.6rem);
          padding-top: calc(70% - 0.6rem);
        }
        @media screen and (max-height: 640px) {
                width: calc(50% - 0.6rem);
                padding-top: calc(50% - 0.6rem);
        }
        @media screen and (min-height: 641px)and (max-height: 700px) {
          width: calc(65% - 0.6rem);
          padding-top: calc(65% - 0.6rem);
        }
        .image{
          width: 100%;
          height: 100%;
          border-radius: 100%;
          // background-color: rgba(0, 0, 0, 0.3);
          background-color: #fff;
          overflow: hidden;
          border: 5px solid #fff;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          box-shadow: 0px 6px 7px rgba(0,0,0,0.3);
        }
      }
      .modal-item-content{
        // height: calc(40% - 26px);
        flex-grow: 1;
        overflow-y: auto;
        .modal-item-desc{
          font-size: 1.3rem;
          font-family: "Oswald", sans-serif;
          color: #fff;
        }
        .share-icon{
          img{
            max-height: 35px;
          }
        }
        .modal-pop-btn{
          border-radius: 4px;
          color: #fff;
          height: 35px;
          margin-left: 1.3rem;
        }
        .badge-date{
          font-size: 0.9rem;
          letter-spacing: 0.7px;
        }
      }

      

      .tap-to-close{
        font-family: "Oswald", sans-serif;
        text-align: center;
        color: #fff;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 0.3rem 0;
        font-size: 0.7rem;
      }
    }
    &.slide{
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.5s ease;
    }
    


  }