.avatar-hor-image{
    display: flex;
    overflow-x: auto;
    div{
        width: 250px;
        height: 400px;
        transition: all 0.5s ease;
        &.zoom {
            // width: 100%;
            // height: 100%;
            width: 100%;
            height: 80%;
            transition: all 0.5s ease;
            position: absolute;
            text-align: center;
            img {
              height: 97%;
            }
          }
        img{
            height: 420px;
        }
    }
}